@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
    .animation-delay-200 {
      animation-delay: 0.2s;
    }
    .animation-delay-400 {
      animation-delay: 0.4s;
    }
}

@font-face {
  font-family: 'Open Sans';
  src: url('~/public/OpenSans-Bold.woff2') format('woff2'),
      url('~/public/OpenSans-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.circle {
  @apply w-4 h-4 my-12 mx-1 bg-orange-500 rounded-full;
}


.txp-markdown {
    ul {
        @apply list-disc ml-10;
    }
}

.txp-btn {
  @apply rounded inline-block leading-none px-4 py-2 mt-0 whitespace-nowrap text-white text-sm;
}

.txp-search-input::-webkit-search-cancel-button {
  position: relative;
  @apply right-1.5;
  font-size: x-large;
}

.txp-blog-content p {
  @apply mb-3;
}

.txp-orange {
  color: #ff914D;
}

.txp-home-ad {
  background-image: url(~/public/home_header-1920x1080.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-family: 'Open Sans';
}
